<template>
  <div class="pa-3">
    <v-form ref="form" lazy-validation>
      <v-dialog
        v-model="showForm"
        max-width="1300px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ title }}</span>
            {{
              (editedItem.id > 0 ? "" : "New Cement Log") +
              (editedItem.id > 0 ? "Cement Log # " + editedItem.code : "")
            }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-tabs v-model="tab0" v-if="editedItem.id > 0">
              <v-tabs-slider></v-tabs-slider>
              <v-tab key="1" href="#1"> Test Data </v-tab>
              <v-tab key="2" href="#2"> Events Log </v-tab>
              <v-tab key="3" href="#3">
                Attachments <v-icon> mdi-paperclip </v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab0">
              <v-tab-item :value="'1'">
                <v-container>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="3" md="3">
                        <v-select
                          :items="well_list"
                          v-model="editedItem.well_id"
                          item-value="id"
                          item-text="wellname"
                          label="Well"
                          dense
                          :rules="[(v) => !!v || 'Well required']"
                          @change="well_change"
                          readonly
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="2" md="2">
                        <v-select
                          :items="users_exe"
                          v-model="editedItem.techid"
                          item-value="id"
                          item-text="name"
                          label="Tested by"
                          :rules="[(v) => !!v || 'Tested by required']"
                          dense
                          @change="test_change"
                          :readonly="test_readonly || editedItem.statut_id >= 5"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" v-if="editedItem.id > 0">
                        <datepicker
                          label="Test Date"
                          :edit="!test_readonly"
                          v-model="editedItem.testdate"
                          :rules="[(v) => !!v || 'Test Date required']"
                          :date_max="today"
                          :date_min="
                            editedItem.statut_id != 7 ? date_min : null
                          "
                          :key="cs"
                          @change="test_change"
                          :clearable="false"
                        ></datepicker>
                      </v-col>

                      <v-col cols="12" sm="2" md="2" v-if="editedItem.id > 0">
                        <v-select
                          :items="[
                            { id: 'd', label: 'Drilling' },
                            { id: 'w', label: 'WorkOver' },
                            { id: 'r', label: 'Rigless' },
                          ]"
                          v-model="editedItem.logmethod"
                          item-value="id"
                          item-text="label"
                          label="Logging Method"
                          :rules="[(v) => !!v || 'Logging Method required']"
                          :readonly="cements_list.length > 0 || test_readonly"
                          dense
                          @change="test_change"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" v-if="editedItem.id > 0">
                        <v-select
                          :items="tool_list"
                          v-model="editedItem.tool_id"
                          item-value="id"
                          item-text="description"
                          label="Tool"
                          :rules="[(v) => !!v || 'Tool required']"
                          dense
                          :readonly="cements_list.length > 0 || test_readonly"
                          @change="test_change"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" v-if="editedItem.id > 0">
                        <v-select
                          :items="welltubulars"
                          v-model="welltubulars_selected"
                          item-value="id"
                          item-text="label"
                          label="Tubulars"
                          :rules="[
                            (v) =>
                              welltubulars_selected.length > 0 ||
                              'Tubulars to required',
                          ]"
                          outlined
                          dense
                          :disabled="!editedItem.well_id"
                          chips
                          multiple
                          :readonly="test_readonly"
                          :key="st"
                          @change="tubular_change"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="1" md="1" v-if="editedItem.id > 0">
                        <v-text-field
                          autocomplete="off"
                          type="number"
                          dense
                          v-model.number="editedItem.logfrom"
                          :label="'Log from'"
                          :disabled="welltubulars_selected.length == 0"
                          hide-spin-buttons
                          :rules="[
                            (v) => v != null || 'Log from required',
                            (v) =>
                              v >= tot_depth_min ||
                              'Min Log from:' + tot_depth_min,
                            (v) =>
                              editedItem.logfrom < editedItem.logto ||
                              editedItem.logto == null ||
                              editedItem.logto == '' ||
                              'Error Log from > Log to',
                          ]"
                          :readonly="cements_list.length > 0 || test_readonly"
                          @input="test_change"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="1" md="1" v-if="editedItem.id > 0">
                        <v-text-field
                          autocomplete="off"
                          type="number"
                          dense
                          v-model.number="editedItem.logto"
                          :label="'Log to'"
                          :disabled="welltubulars_selected.length == 0"
                          :rules="[
                            (v) => !!v || 'Log to required',
                            (v) =>
                              v <= tot_depth_max ||
                              'Max Log to:' + tot_depth_max,
                            (v) =>
                              editedItem.logto > editedItem.logfrom ||
                              editedItem.logfrom == null ||
                              editedItem.logfrom == '' ||
                              'Error Log from > Log to',
                          ]"
                          hide-spin-buttons
                          :readonly="cements_list.length > 0 || test_readonly"
                          @input="test_change"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          outlined
                          dense
                          v-model="editedItem.editcomment"
                          label="Comment"
                          @input="test_change"
                          :readonly="test_readonly"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-container>
                <v-container fluid>
                  <v-tabs v-model="tab1" v-if="editedItem.id > 0">
                    <v-tab
                      key="1"
                      href="#1"
                      v-if="welltubulars_selected.length > 0"
                      >Log Detail</v-tab
                    >
                    <v-tab key="2" href="#2" v-if="editedItem.statut_id != 7">
                      Actual Cemment Log
                      <div style="display: inline-block; position: relative">
                        <v-badge
                          :color="'orange'"
                          v-if="anomalies > 0"
                          overlap
                          bottom
                          right
                        >
                          <template v-slot:badge>{{ anomalies }}</template>
                          <v-icon>mdi-alert</v-icon>
                        </v-badge>
                      </div>
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab1" v-if="editedItem.id > 0">
                    <v-tab-item :value="'1'">
                      <v-form
                        v-if="
                          editedItem.id >= 0 &&
                          (editedItem.statut_id == 4 ||
                            editedItem.statut_id == 5 ||
                            editedItem.statut_id == 8) &&
                          welltubulars_selected.length > 0 &&
                          editedItem.logto > 0 &&
                          editedItem.logfrom >= 0
                        "
                        ref="ref_cem"
                        lazy-validation
                      >
                        <v-container>
                          <v-row v-if="!test_readonly">
                            <v-col cols="12" sm="5" md="4">
                              <v-autocomplete
                                :items="tubulars_sizes"
                                v-model="cement.well_tubular_id"
                                item-value="id"
                                item-text="label"
                                label="sizes"
                                :rules="[(v) => !!v || 'String required']"
                                outlined
                                dense
                                @input="cr_changed"
                                @change="string_change"
                                return-object
                              >
                                <template v-slot:selection="{ item }">
                                  {{ item.label }}
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list-item-content>
                                    {{ item.label }}
                                    <v-list-item-subtitle>
                                      {{
                                        "Top Cement " +
                                        numberWithSpace(item.topcement) +
                                        " " +
                                        depth_unit
                                      }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                      {{
                                        "Depth From " +
                                        numberWithSpace(item.depthfrom) +
                                        " " +
                                        depth_unit
                                      }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                      {{
                                        "Depth To " +
                                        numberWithSpace(
                                          item.depthfrom != null
                                            ? item.depthto
                                            : 0
                                        ) +
                                        " " +
                                        depth_unit
                                      }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                              <v-text-field
                                autocomplete="off"
                                type="number"
                                outlined
                                dense
                                ref="cem_from"
                                v-model.number="cement.depthfrom"
                                :label="'Depth From (' + depth_unit + ')'"
                                :rules="[
                                  (v) => v != null || 'Depth From required',
                                  (v) =>
                                    v >= depth_min ||
                                    'Error Min :' + numberWithSpace(depth_min),
                                  (v) =>
                                    v < depth_max ||
                                    'Error Max : ' + numberWithSpace(depth_max),
                                  (v) =>
                                    v < cement.depthto ||
                                    cement.depthto == null ||
                                    'Depth To <= Depth From !!',
                                  (v) =>
                                    (v >= 0 && verif_df) ||
                                    'Depth From exist !!',
                                ]"
                                :disabled="!cement.well_tubular_id"
                                @input="cr_changed"
                                @focus="$event.target.select()"
                                hide-spin-buttons
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                              <v-text-field
                                autocomplete="off"
                                type="number"
                                outlined
                                dense
                                v-model.number="cement.depthto"
                                :label="'Depth To (' + depth_unit + ')'"
                                :rules="[
                                  (v) => !!v || 'Depth To required',
                                  (v) =>
                                    v > depth_min ||
                                    'Error Min :' + numberWithSpace(depth_min),
                                  (v) =>
                                    v <= depth_max ||
                                    'Error Max : ' + numberWithSpace(depth_max),
                                  (v) =>
                                    v > cement.depthfrom ||
                                    cement.depthfrom == null ||
                                    'Depth To <= Depth From !!',
                                  (v) => !v || verif_dt || 'Depth To exist !!',
                                ]"
                                :disabled="
                                  !cement.well_tubular_id ||
                                  cement.depthfrom == null
                                "
                                @input="cr_changed"
                                @focus="$event.target.select()"
                                hide-spin-buttons
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                              <v-select
                                :items="level_list"
                                v-model="cement.level_id"
                                item-value="id"
                                item-text="label"
                                label="Evaluation"
                                :rules="[(v) => !!v || 'Evaluation required']"
                                outlined
                                dense
                                :disabled="
                                  !cement.well_tubular_id ||
                                  cement.depthfrom == null ||
                                  cement.depthto == null ||
                                  hasLevel == 1
                                "
                                @change="levelchange"
                                return-object
                                @keydown.enter="CementSave"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                @click.stop="CementSave"
                                class="ma-1"
                                :disabled="
                                  cement.well_tubular_id == null ||
                                  cement.depthfrom == null ||
                                  cement.depthto == null ||
                                  cement.level_id == null ||
                                  change_cr == 0 ||
                                  btn_click
                                "
                              >
                                Save
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                @click.stop="cancel"
                                :disabled="
                                  cement.well_tubular_id == null &&
                                  cement.depthfrom == null &&
                                  cement.depthto == null &&
                                  cement.level_id == null
                                "
                              >
                                Cancel
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <listitems
                            :list="cements_list"
                            :title="'Detail Cements'"
                            :headers="cements_headers"
                            :showstd="false"
                            :showedit="false"
                            :master="false"
                            :key="cem_key"
                            :ipg="-1"
                            :hdf="true"
                            :add="false"
                            :del="false"
                            :btn_update="!test_readonly"
                            @btn_update_click="OpenImportForm"
                            @col_btn1_click="CementOpen"
                            @col_btn2_click="element_delete"
                          >
                          </listitems>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item :value="'2'">
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <listitems
                            :list="tubulars_list"
                            :title="'Casings'"
                            :headers="tubulars_headers"
                            :showstd="false"
                            :showedit="false"
                            :master="true"
                            :key="cas_key"
                            @rowselect="TubularSelect"
                            :ipg="-1"
                            :hdf="true"
                            :add="false"
                            :del="false"
                          >
                          </listitems>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" v-if="tubular.id">
                          <v-row>
                            <v-col cols="12" sd="12" md="12">
                              <listitems
                                :list="tubular.last_cements"
                                :title="'Cement'"
                                :headers="cement_headers2"
                                :showstd="false"
                                :showedit="false"
                                :master="false"
                                :key="tc_key"
                                :ipg="-1"
                                :hdf="true"
                                :add="false"
                                :del="false"
                              >
                              </listitems>
                            </v-col>
                            <v-col cols="12" sd="12" md="12">
                              <v-radio-group
                                v-model="tubular.decision"
                                row
                                mandatory
                                :label="'Data Cement for ' + tubular.item"
                                dense
                                v-if="tubular.anomalies > 0"
                              >
                                <v-radio label="Anomalies" :value="0"></v-radio>
                                <v-radio label="Update" :value="1"></v-radio>
                                <v-radio label="Maintain" :value="-1"></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
            <v-tab-item :value="'2'">
              <listitems
                :list="events"
                :headers="events_header"
                :toolbar="false"
                :key="kev"
              >
              </listitems>
            </v-tab-item>
            <v-tab-item :value="'3'">
              <filelist
                :item="editedItem"
                :editer="true"
                :doc_type="2"
                :isform="false"
                :auth="'01007'"
                :well_id="parseInt(editedItem.well_id)"
              >
              </filelist
            ></v-tab-item>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <Test_Accept
              :test="editedItem"
              :key="ta"
              @close="close"
              @test_save="test_save"
              @status_change="status_change"
              @validate_test="validate_test"
              @accept_test="accept_test"
              :testype_id="9"
              :changes="changes"
              :save_disable="save_disable"
              :invalid_test_tubulars="
                tubulars_list
                  .filter((elm) => elm.decision == -1)
                  .map((elm) => elm.id)
              "
            ></Test_Accept>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <confirmdialog ref="confirm" />
    <import_data
      :init_list="init_list"
      :showForm="!isListClose"
      :title="title2"
      :listheaders="listheaders"
      :items="import_list"
      :item_key="'id'"
      :col_key="'Cement '"
      :item_id="'id'"
      :width="width"
      :alert="alert"
      :type_alert="type_alert"
      :message="message"
      @close="isListClose = true"
      @save_import="save_import"
      :key="frml"
    ></import_data>
  </div>
</template>

<script>
function checkRangeCompliance(arr1, arr2) {
  arr1.forEach((item) => {
    let start = item.depthfrom;
    let complies = true;

    while (start < item.depthto) {
      const matchingRange = arr2.find(
        (range) =>
          range.depthfrom <= start &&
          range.depthto > start &&
          range.level_id >= item.level_id
      );
      if (!matchingRange) {
        complies = false;
        break;
      }

      // Move start to the end of the current matching range or the current item’s depthto, whichever is smaller
      start = Math.min(matchingRange.depthto, item.depthto);
    }

    item.complies = complies && start === item.depthto ? 0 : 1;
  });

  return arr1;
}
import CREATE_CEMENT from "../graphql/Well/CREATE_CEMENT.gql";
import UPDATE_CEMENT from "../graphql/Well/UPDATE_CEMENT.gql";
import DELETE_CEMENT from "../graphql/Well/DELETE_CEMENT.gql";
import UPSERT_TEST from "../graphql/WellTest/CCL/UPSERT_TEST.gql";
import CEMENT_IMPORT from "../graphql/WellTest/CCL/CEMENT_IMPORT.gql";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    datepicker: () => import("../components/DatePicker.vue"),
    import_data: () => import("../components/ImportData.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    Test_Accept: () => import("../components/Test_Accept.vue"),
    filelist: () => import("./FileList.vue"),
  },
  props: {
    showForm: Boolean,
    tool_list: Array,
    level_list: Array,
    well_list: Array,
    tubulars_list: Array,
    item: Object,
    user_list: Array,
    depth_unit: String,
    last_cement: Array,
    editer: Boolean,
    valider: Boolean,
    actual_cements: Array,
  },
  data() {
    return {
      events: [],
      events_header: [
        {
          value: "id",
          selected: false,
        },
        {
          text: "Date",
          value: "event_date",
          selected: true,
        },
        {
          text: "Operation",
          value: "statut",
          selected: true,
        },
        {
          text: "Operator",
          value: "operator",
          selected: true,
        },
        {
          text: "Comment",
          value: "comment",
          selected: true,
        },
      ],
      kev: 160,
      tab0: "1",
      save_disable: false,
      test_readonly: false,
      init_list: [],
      isListClose: true,
      width: "1000px",
      alert: false,
      type_alert: "info",
      message: "",
      frml: 600,
      listheaders: [],
      import_list: [],
      welltubulars_selected: [],
      title2: "",
      showtubulars: false,
      changes: 0,
      hasLevel: 0,
      change_cr: 0,
      depth_min: 0,
      depth_max: 0,
      tot_depth_min: 0,
      tot_depth_max: 0,
      operation: "Add",
      cs: 100,
      severity: "",
      isCementClose: true,
      loading: false,
      editedItem: {},
      cement: {},
      well: {},
      tubular: {},
      title: "",
      cem_key: 500,
      ta: 150,
      cements_list: [],
      st: 0,
      last_test: [],
      btn_click: false,
      tab1: "1",
      anomalies: 0,
      cas_key: 1500,
      tc_key: 1600,
      cement_headers2: [
        {
          text: "No",
          value: "no",
          selected: true,
        },
        {
          text: "Depth From",
          value: "depthfrom",
          selected: true,
          slot: "cur33",
          align: "end",
          import: true,
        },
        {
          text: "Depth To",
          value: "depthto",
          selected: true,
          slot: "cur33",
          align: "end",
          import: true,
        },
        {
          text: "Depth",
          value: "depth",
          selected: true,
          slot: "cur33",
          align: "end",
        },

        {
          text: "Level",
          value: "level",
          slot: "chip",
          selected: true,
          color: "color",
        },

        {
          text: "Anomalie",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-alert",
          column: "complies",
          valeur: 1,
          color: "orange",
          sortable: false,
          tooltip:
            "Cement Quality of the interval or part of the interval, not found or smaller",
        },
      ],
      tubulars_headers: [
        {
          text: "Position",
          value: "no",
          selected: true,
          sortable: false,
          index: 0,
        },

        {
          text: "Item",
          value: "item",
          selected: true,
          sortable: false,
          index: 0,
        },
        {
          text: "Size (in)",
          value: "api_in",
          selected: true,
          slot: "text_icon",
          icon: "mdi-dots-horizontal",
          column: "nbsizes",
          valeur: "1",
          width: "250",
          sortable: false,
          index: 0,
        },
        {
          text: "Age (Year)",
          value: "age",
          selected: true,
          sortable: false,
          index: 0,
        },

        {
          text: "Anomalies",
          value: "anomalies",
          selected: true,
          sortable: false,
        },
      ],
      cements_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
        },
        {
          text: "String",
          value: "sizes",
          selected: true,
          import: true,
        },

        {
          text: "Depth From",
          value: "depthfrom",
          selected: true,
          slot: "cur33",
          align: "end",
          import: true,
        },
        {
          text: "Depth To",
          value: "depthto",
          selected: true,
          slot: "cur33",
          align: "end",
          import: true,
        },
        {
          text: "Depth",
          value: "depth",
          selected: true,
          slot: "cur33",
          align: "end",
        },

        {
          text: "Evaluation",
          value: "level",
          selected: true,
          slot: "chip",
          color: "color",
          import: true,
        },
        {
          text: "Update",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-pencil",
          width: "10",
          sortable: false,
        },
        {
          text: "Delete",
          slot: "col_btn2",
          selected: true,
          icon: "mdi-delete",
          width: "10",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    // if (this.last_cement) this.last_test = this.last_cement;
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);

        this.cs++;
        this.cements_headers[6].hiden =
          this.editedItem.statut_id == 6 || this.editedItem.statut_id == 7;
        this.cements_headers[7].hiden =
          this.editedItem.statut_id == 6 || this.editedItem.statut_id == 7;
        this.well_change();
        this.events = this.item.events;
        if (this.editedItem.wellstest_tubulars) {
          this.welltubulars_selected = this.editedItem.wellstest_tubulars.map(
            (elm) => elm.well_tubular_id
          );
          this.welltubulars_selected.sort();
        }
        this.tubular_change();
        if (this.editedItem.cement_details)
          this.cements_list = this.editedItem.cement_details;
        this.cem_key++;
        this.init_last_cement();
      } else {
        this.editedItem = Object.assign({}, { id: -1, statut_id: 4 });
        this.editedItem.testdate = this.$store.state.today;
        this.cs++;
        if (this.well_list)
          if (this.well_list.length == 1) {
            this.editedItem.well_id = this.well_list[0].id;
            this.well_change();
          }
      }
      this.get_status();
      this.ta++;
    }
    this.$nextTick(() => {
      this.changes = 1;
    });
  },
  computed: {
    drillingdate() {
      return this.well ? this.well.drillingdate : this.$store.state.today;
    },
    date_min() {
      let d = this.drillingdate;
      if (this.actual_cements) {
        let i = this.actual_cements.findIndex(
          (elm) => elm.well_id == this.editedItem.well_id
        );
        if (i >= 0) {
          this.actual_cements[i].tubulars.forEach((element) => {
            if (element.logRanges)
              element.logRanges.forEach((element2) => {
                if (d == null || d < element2.testdate) d = element2.testdate;
              });
          });
        }
      }
      if (d) {
        const todayDate = new Date(d);
        todayDate.setDate(todayDate.getDate() + 1);
        d = todayDate.toISOString().slice(0, 10);
      }
      return d;
    },
    today() {
      return this.$store.state.today;
    },
    users_exe() {
      let l = [];
      if (this.user_list)
        l = this.user_list.filter((elm) => elm.autorisations.includes("06003"));
      return l;
    },
    users_eval() {
      let l = [];
      if (this.user_list)
        l = this.user_list.filter((elm) => elm.autorisations.includes("07003"));
      return l;
    },
    tubulars_sizes() {
      let l = [];
      if (this.tubulars_list && this.welltubulars_selected) {
        this.tubulars_list
          .filter(
            (elm) =>
              elm.well_id == this.editedItem.well_id &&
              this.welltubulars_selected.includes(elm.id)
          )
          .forEach((element) => {
            let elm = Object.assign({}, element);
            elm.disabled = false;
            l.push(elm);
          });
      }

      return l;
    },

    welltubulars() {
      let l = [];
      let l2 = [];
      if (this.tubulars_list) {
        l = this.tubulars_list.filter(
          (elm) => elm.well_id == this.editedItem.well_id && elm.tubular_id != 1
        );
      }
      l.sort(this.sortBy("csg_order"));

      for (let index = 0; index < l.length; index++) {
        const element = l[index];
        let j = this.welltubulars_selected.findIndex(
          (elm) => elm != element.id && element.depthfrom == 0
        );
        let k = this.cements_list.findIndex(
          (elm) => elm.well_tubular_id == element.id
        );
        element.disabled = j >= 0 || k >= 0;
        l2.push(element);
      }
      return l2;
    },

    verif_df() {
      let i = this.cements_list.findIndex(
        (elm) =>
          this.cement.id != elm.id &&
          this.cement.well_tubular_id == elm.well_tubular_id &&
          ((elm.depthfrom > this.cement.depthfrom &&
            elm.depthfrom < this.cement.depthto) ||
            (this.cement.depthfrom > elm.depthfrom &&
              this.cement.depthfrom < elm.depthto))
      );
      return i == -1;
    },
    verif_dt() {
      let i = this.cements_list.findIndex(
        (elm) =>
          this.cement.id != elm.id &&
          this.cement.well_tubular_id == elm.well_tubular_id &&
          ((elm.depthfrom > this.cement.depthfrom &&
            elm.depthfrom < this.cement.depthto) ||
            (this.cement.depthfrom > elm.depthfrom &&
              this.cement.depthfrom < elm.depthto))
      );
      return i == -1;
    },
  },
  watch: {},
  methods: {
    init_last_cement() {
      this.tubulars_list.forEach((element) => {
        element.last_cements = [];
        element.anomalies = 0;
        if (!element.decision) element.decision = 0;
        let i = this.actual_cements.findIndex(
          (elm) => elm.well_id == this.editedItem.well_id
        );
        if (i >= 0) {
          let j = this.actual_cements[i].tubulars.findIndex(
            (elm) => elm.well_tubular_id == element.id
          );
          if (j >= 0)
            element.last_cements =
              this.actual_cements[i].tubulars[j].cementRanges;
        }
      });
    },
    verif_last_cement() {
      this.alert = false;
      this.init_last_cement();
      this.tubulars_list
        .filter((elm) => this.welltubulars_selected.includes(elm.id))
        .forEach((element) => {
          element.last_cements = checkRangeCompliance(
            element.last_cements,
            this.cements_list
          );
        });
      this.tubulars_list
        .filter((elm) => this.welltubulars_selected.includes(elm.id))
        .forEach((element) => {
          element.anomalies = element.last_cements.reduce(
            (a, b) => a + (b["complies"] || 0),
            0
          );
        });
      this.anomalies = this.tubulars_list.reduce(
        (a, b) => a + (b["anomalies"] || 0),
        0
      );

      this.cas_key++;
      let l = this.tubulars_list.filter((elm) =>
        this.welltubulars_selected.includes(elm.id)
      );

      let i = l.findIndex((elm2) => elm2.anomalies > 0 && elm2.decision == 0);
      if (i >= 0) {
        this.message = "Anomalies must be resolved before validating test!";
        this.type_alert = "error";
        this.alert = true;
      }
      return i == -1;
    },
    accept_test(callback) {
      callback(this.verif_last_cement());
    },
    get_status() {
      this.test_readonly =
        (this.editedItem.statut_id != 4 &&
          this.editedItem.statut_id != 5 &&
          this.editedItem.statut_id != 8) ||
        !this.editer;
      if (this.editedItem.statut_id == 4) this.editedItem.status = "Attributed";
      if (this.editedItem.statut_id == 5)
        this.editedItem.status = "In Progress";
      if (this.editedItem.statut_id == 6) this.editedItem.status = "Completed";
      if (this.editedItem.statut_id == 7) this.editedItem.status = "Accepted";
      if (this.editedItem.statut_id == 8) this.editedItem.status = "Rejected";
      this.cements_headers[6].hiden = this.editedItem.statut_id == 6;
      this.cements_headers[7].hiden = this.editedItem.statut_id == 6;
    },
    status_change(status) {
      this.editedItem.statut_id = status;
      this.get_status();
      this.$emit("update", this.editedItem);
    },
    verif_df_list(list, cem) {
      let i = list.findIndex(
        (elm) =>
          cem.id != elm.id &&
          cem.well_tubular_id == elm.well_tubular_id &&
          ((cem.depthfrom >= elm.depthfrom && cem.depthfrom < elm.depthto) ||
            (elm.depthfrom > cem.depthfrom && elm.depthfrom < cem.depthto))
      );

      if (i == -1)
        i = this.cements_list.findIndex(
          (elm) =>
            cem.id != elm.id &&
            cem.well_tubular_id == elm.well_tubular_id &&
            ((cem.depthfrom >= elm.depthfrom && cem.depthfrom < elm.depthto) ||
              (elm.depthfrom > cem.depthfrom && elm.depthfrom < cem.depthto))
        );
      return i == -1;
    },
    verif_dt_list(list, cem) {
      let i = list.findIndex(
        (elm) =>
          cem.id != elm.id &&
          cem.well_tubular_id == elm.well_tubular_id &&
          ((cem.depthfrom > elm.depthfrom && cem.depthfrom < elm.depthto) ||
            (elm.depthfrom > cem.depthfrom && elm.depthfrom < cem.depthto))
      );
      if (i == -1)
        i = this.cements_list.findIndex(
          (elm) =>
            cem.id != elm.id &&
            cem.well_tubular_id == elm.well_tubular_id &&
            ((cem.depthfrom > elm.depthfrom && cem.depthfrom < elm.depthto) ||
              (elm.depthfrom > cem.depthfrom && elm.depthfrom < cem.depthto))
        );
      return i == -1;
    },
    sortBy() {
      return function (a, b) {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      };
    },
    TubularSelect(item) {
      if (item) {
        this.tubular = item;
        this.tc_key++;
      }
    },
    OpenImportForm() {
      this.init_list = [];
      this.form_list = [];
      this.title2 = "Import Cement data ";
      this.listheaders = this.cements_headers.filter(
        (elm) => elm.import == true
      );
      this.tubulars_sizes.forEach((el) => {
        this.init_list.push({ id: el.id, sizes: el.label });
      });
      this.listheaders[0].default = this.cement.item;
      this.width = "1000px";
      this.orientation = "p";
      this.isListClose = false;
      this.imp_si = true;
      this.frml++;
    },
    async save_import(items) {
      let ok = true;
      this.message = null;
      let verif = true;
      let list = [];
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        if (element.depthfrom == null || typeof element.depthfrom != "number") {
          verif = false;
          ok = false;
          this.message = "Invalid depthfrom value" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          break;
        }
        if (
          (element.depthto == null || typeof element.depthto != "number") &&
          ok
        ) {
          verif = false;
          ok = false;
          this.message = "Invalid depthto value" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          break;
        }
        if (
          (element.level == null ||
            typeof element.level != "number" ||
            ![1, 2, 3, 4, 5].includes(element.level)) &&
          ok
        ) {
          verif = false;
          ok = false;
          this.message = "Invalid level value" + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          break;
        }
        let k = this.tubulars_sizes.findIndex(
          (x) => x.label.trim() === element.sizes.trim()
        );
        if (ok) {
          if (k < 0) {
            verif = false;
            ok = false;
            this.message = "Invalid casing value" + " - ligne " + (index + 1);
            this.type_alert = "error";
            this.alert = true;
            break;
          } else {
            let t = this.tubulars_sizes[k];
            element.well_tubular_id = t.id;
            if (
              element.depthfrom >= element.depthto ||
              element.depthfrom < t.depthfrom ||
              element.depthto > t.depthto
            ) {
              verif = false;
              ok = false;
              this.message = "Invalid value depth" + " - ligne " + (index + 1);
              this.type_alert = "error";
              this.alert = true;
              break;
            }
          }
        }
        if (ok) {
          if (!this.verif_df_list(items, element)) {
            verif = false;
            ok = false;
            this.message = "Depth From exist" + " - ligne " + (index + 1);
            this.type_alert = "error";
            this.alert = true;
            break;
          }
          if (!this.verif_dt_list(items, element)) {
            verif = false;
            ok = false;
            this.message = "Depth To exist" + " - ligne " + (index + 1);
            this.type_alert = "error";
            this.alert = true;
            break;
          }
        }

        if (verif) {
          list.push({
            depthfrom: element.depthfrom,
            depthto: element.depthto,
            level_id: element.level,
            well_id: this.editedItem.well_id,
            welltest_id: this.editedItem.id,
            well_tubular_id: element.well_tubular_id,
          });
        }
      }
      if (ok) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Are you sûr to update cements data?",
            { color: "orange darken-3" }
          )
        ) {
          let v = {
            list: list,
          };
          let r = await this.$maj(CEMENT_IMPORT, v);
          if (r.ok) {
            this.cements_list = JSON.parse(r.data.ImportCements);
            this.isListClose = true;
            this.cem_key++;
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }
      }
    },
    well_change() {
      this.well = null;
      this.welltubulars_selected = [];
      this.$refs.form.resetValidation();
      let i = this.well_list.findIndex(
        (elm) => elm.id == this.editedItem.well_id
      );
      if (i >= 0) {
        this.well = this.well_list[i];
      }
      this.changes++;
    },
    tubular_change() {
      let l = [];
      if (this.welltubulars_selected)
        l = this.welltubulars.filter((elm) =>
          this.welltubulars_selected.includes(elm.id)
        );
      if (l.length > 0) {
        l.sort(this.sortBy("csg_order"));
        this.tot_depth_min = l[0].topcement;
        this.tot_depth_max = l[0].depthto;
        l.forEach((element) => {
          if (this.tot_depth_min > element.topcement)
            this.tot_depth_min = element.topcement;
          if (this.tot_depth_max < element.depthto)
            this.tot_depth_max = element.depthto;
        });
      }
      this.changes++;
    },

    test_change() {
      this.changes++;
    },
    cr_changed() {
      this.change_cr = 1;
    },
    levelchange(item) {
      this.cement.level = item.label;
      this.cement.level_id = item.id;
      this.cement.color = item.color;
    },
    string_change(item) {
      if (item) {
        this.depth_min = item.topcement;
        if (this.depth_min < this.editedItem.logfrom)
          this.depth_min = this.editedItem.logfrom;
        this.depth_max = item.depthto;
        if (this.depth_max > this.editedItem.logto)
          this.depth_max = this.editedItem.logto;
        this.cement.well_tubular_id = item.id;
        this.cement.item = item.item;
        this.change_cr = 1;
      }
    },
    async validate_test(callback) {
      if (this.cements_list.length == 0) return;
      callback(this.$refs.form.validate());
    },
    cancel() {
      if (!this.btn_click) {
        this.btn_click = true;

        this.$refs.ref_cem.resetValidation();
        this.$nextTick(() => {
          this.cement = {};
        });

        this.btn_click = false;
      }
    },
    async test_save() {
      if (this.$refs.form.validate()) {
        this.save_disable = true;
        this.editedItem.testtype_id = 9;
        let statut_id = this.editedItem.id >= 0 ? 5 : 4;
        let v = {
          test: [
            {
              id: this.editedItem.id >= 0 ? this.editedItem.id : null,
              well_id: this.editedItem.well_id,
              logfrom: this.editedItem.logfrom,
              logto: this.editedItem.logto,
              statut_id: statut_id,
              techid: this.editedItem.techid,
              editcomment: this.editedItem.editcomment,
              testtype_id: this.editedItem.testtype_id,
              testdate: this.editedItem.testdate,
              tool_id: this.editedItem.tool_id,
              logmethod: this.editedItem.logmethod,
            },
          ],
          welltests_tubulars: this.welltubulars_selected,
        };

        let r = await this.$maj(UPSERT_TEST, v);
        if (r.ok) {
          this.editedItem = r.data.UpsertTest;
          this.changes = 1;
          this.$refs.form.resetValidation();
          this.$emit("change", this.editedItem);
          this.get_status();
        }
        this.save_disable = false;
      }
    },
    close() {
      this.$emit("close");
    },
    async element_delete(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Delete this item !!-Are you sur ?",
          { color: "orange darken-3" }
        )
      ) {
        this.data_loading = true;
        let r = await this.$maj(DELETE_CEMENT, { id: item.id });
        if (r) {
          this.cements_list.splice(item.index, 1);
          this.$emit("change", this.cement);
        } else this.data_loading = false;
      }
    },

    CementOpen(item) {
      this.cement = item;
    },

    async CementSave() {
      if (!this.btn_click) {
        this.btn_click = true;
        if (this.$refs.ref_cem.validate()) {
          this.cement.depth = this.cement.depthto - this.cement.depthfrom;
          this.cement.expire = this.cement.depth < 0;

          let i = this.tubulars_list.findIndex(
            (elm) => elm.id == this.cement.well_tubular_id
          );
          if (i >= 0) this.cement.string = this.tubulars_list[i].item;
          i = this.level_list.findIndex(
            (elm) => elm.id == this.editedItem.level_id
          );
          if (i >= 0) this.cement.color = this.level_list[i].color;
          if (this.cement.id > -1) {
            let v = {
              wellscement: {
                id: this.cement.id,
                well_id: this.editedItem.well_id,
                welltest_id: this.editedItem.id,
                well_tubular_id: this.cement.well_tubular_id,
                depthfrom: this.cement.depthfrom,
                depthto: this.cement.depthto,
                level_id: this.cement.level_id,
                write_uid: this.$store.state.me.id,
              },
            };
            let r = await this.$maj(UPDATE_CEMENT, v);
            if (r.ok) {
              this.cem_key++;
              this.cement = {};
              this.change_cr = 0;
              this.$refs.ref_cem.resetValidation();
              this.$emit("change", this.editedItem);
            }
          } else {
            let v = {
              wellscement: {
                well_id: this.editedItem.well_id,
                welltest_id: this.editedItem.id,
                well_tubular_id: this.cement.well_tubular_id,
                level_id: this.cement.level_id,
                depthfrom: this.cement.depthfrom,
                depthto: this.cement.depthto,
                create_uid: this.$store.state.me.id,
              },
            };
            let r = await this.$maj(CREATE_CEMENT, v);
            if (r.ok) {
              this.cement.id = r.data.createWellsCement.id;
              let j = this.tubulars_sizes.findIndex(
                (elm) => elm.id == this.cement.well_tubular_id
              );
              if (j >= 0) this.cement.sizes = this.tubulars_sizes[j].label;
              this.cements_list.push(this.cement);

              this.cem_key++;
              let id = this.cement.well_tubular_id;
              this.change_cr = 0;
              let s = this.cement.item;
              this.cement = {
                well_tubular_id: id,
                item: s,
                level_id: null,
                depthfrom: null,
                depthto: null,
              };
              this.$emit("change", this.editedItem);
              this.$refs.ref_cem.resetValidation();
              this.$refs.cem_from.focus();
            }
          }
          this.changes = 1;
        }
        this.btn_click = false;
      }
    },
    numberWithSpace: function (x) {
      return x
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },
  },
};
</script>
